import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PublicComponent} from './public/public.component';

const appRoutes: Routes = [
    {
      path: '',
      component: PublicComponent,
      resolve: {
      }
    },
  {
      path: 'public',
      component: PublicComponent,
      resolve: {
      }
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {
}
