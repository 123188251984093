import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';


@Component({
    templateUrl: 'public.component.html',
})
export class PublicComponent implements OnInit {
    public navIsFixed: boolean = false;
    public content: any;
    public showMenuMobile: boolean = false;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        public route: ActivatedRoute,
    ) { }

    ngOnInit() {
        this.route.data.subscribe((data: {
            content: any,
        }) => {
            this.content = data.content;
        });
    }

    @HostListener('window:scroll', [])
    onWindowScroll() {
        const x = /*this.window.pageYOffset || */this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
        if (x > 100) {
            this.navIsFixed = true;
        } else if (this.navIsFixed && x < 10) {
            this.navIsFixed = false;
        }
    }

    toggleMenuMobile() {
        this.showMenuMobile = !this.showMenuMobile;
    }
}
