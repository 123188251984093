import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'neuropia-public';

  public navIsFixed: boolean = false;
  public content: any;
  public showMenuMobile: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.route.data.subscribe((data: {
      content: any,
    }) => {
      console.log('init', data);
      this.content = data.content;
    });
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const x = /*this.window.pageYOffset || */this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
    if (x > 100) {
      this.navIsFixed = true;
    } else if (this.navIsFixed && x < 10) {
      this.navIsFixed = false;
    }
  }

  toggleMenuMobile() {
    this.showMenuMobile = !this.showMenuMobile;
  }
}
